import { Typography, Button, Chip, Dialog, Grid, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import SelectPlanModalQuotit from "components/HealthPlan/SelectPlanModalQuotit";
import ViewDetailsModalQuotit from "components/HealthPlan/ViewDetailsModalQuotit";
import { useAuth } from "contexts/User";
import { useState, useRef, useEffect } from "react";
import { FormattedNumber } from "react-intl";
import { QuotitHealthPlanDetails } from "services/Interfaces"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function PlanOptionsJamie({ plans, speed, finished, autoScroll, cobraEstimate }: { plans: Array<QuotitHealthPlanDetails>, speed: number, finished?: Function, autoScroll?: Function, cobraEstimate: number }) {
  const [interval, setNewInterval] = useState<any>();
  const [complete, setComplete] = useState<boolean>(false);
  const scrollSpan = useRef<any>();
  const [openViewPlan, setOpenViewPlan] = useState(false);
  const [planToView, setPlanToView] = useState<QuotitHealthPlanDetails | null>(null)
  const [openChoosePlan, setOpenChoosePlan] = useState(false);
  const [planToChoose, setPlanToChoose] = useState<QuotitHealthPlanDetails | null>(null);
  const { answers } = useAuth();

  const federalSubsidyAmount = answers?.data()?.insuranceDetails.federalSubsidyAmount
 
  function getSubsidyAmount(originalPremium: number, subsidyAmount: number) {
    if (subsidyAmount < originalPremium) {
      return originalPremium - subsidyAmount;
    }
  }

  useEffect(() => {
    if (!interval) {
      let newInterval = setInterval(() => {
        setComplete(true);
        if (finished) {
          finished();
        }
        clearInterval(newInterval);
      }, speed);
      setNewInterval(newInterval);
    }
  }, [complete, finished, interval, speed,])
  useEffect(() => {
    if (scrollSpan.current) {
      if (typeof autoScroll === "function") {
        autoScroll();
      }
    }
  }, [autoScroll]);
  return (
    <>
      <Grid container spacing={0.25} sx={{
        padding: 1,
        backgroundColor: "#F1F1F1",
        borderRadius: "17px 17px 17px 2px",
      }}>
        {plans.map((plan) => {
          const save = cobraEstimate - plan.rate;
          let outOfPocketMax = "$0";
          let deductible = "$0";
          plan.benefits.forEach((benefit: any) => {
            if (benefit.enum === "ACA1EHBDrugOOPMax") {
              outOfPocketMax = benefit.inNetwork;
            }
          });
          plan.benefits.forEach((benefit: any) => {
            if (benefit.enum === "ACA1MedicalDrugDeductible") {
              deductible = benefit.inNetwork;
            }
          });
          return (
            <Grid item xs={12} sm={4} key={plan.id} sx={{ borderRadius: "10px", overflow: "hidden" }}>
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "#F2EDE7",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: 'center',
                  height: { xs: 110, sm: 150 },
                  cursor: "pointer",
                  marginTop: { xs: 1, sm: 0 },
                }}
              >
                <Tooltip
                  title={
                    <Box sx={{ backgroundColor: "#FFFFFF", border: "none" }}>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        {plan.name}
                      </Typography>
                    </Box>
                  }
                  arrow
                  placement="top"
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: '#FFFFFF',
                        border: 'none',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                      },
                      '& .MuiTooltip-arrow': {
                        color: '#FFFFFF',
                      },
                    },
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3,
                      height: "100%",
                      maxHeight: "4em",
                      fontSize: { xs: "0.9rem", sm: "1.2rem" }
                    }}
                  >
                    {plan.name}
                  </Typography>
                </Tooltip>
                <Typography variant="h5" sx={{ fontSize: { xs: "0.9rem", sm: "1.2rem" } }}>{plan.planType}</Typography>
              </Box>
              <Grid container>
                <Grid item xs={6} sm={12}>
                  <Box sx={{ p: 2, backgroundColor: "#FFFFFF", display: "flex", flexDirection: "column", alignItems: "center", textAlign: 'center', height: 120, borderBottom: "2px solid #F2EDE7", borderRight: { xs: "1px solid #F2EDE7", sm: "none" } }}>
                    <Typography variant="h6" sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}>Monthly Premium</Typography>
                    <Typography fontSize={16} color="#000" fontWeight={600}>
                      {federalSubsidyAmount && plan.rate > federalSubsidyAmount ? (
                        <Tooltip sx={{marginLeft:"10px"}} title={`This premium amount includes an estimated premium tax credit amount of $${federalSubsidyAmount} per month, based on the income and household information you have provided. The exact amount may vary based on your final application and actual income.`} arrow>
                          <span style={{ cursor: 'pointer', display: "flex", flexDirection: "column" }}>
                            <span style={{ textDecoration: 'line-through', marginRight: 5 }}>
                              <FormattedNumber value={plan.rate ? plan.rate : 0} style={`currency`} currency="USD" />
                            </span>
                            <span style={{ verticalAlign: 'middle', display: "flex", flexDirection: "row" }}>
                              <FormattedNumber value={getSubsidyAmount(plan.rate, federalSubsidyAmount) || 0} style={`currency`} currency="USD" />
                              <InfoOutlinedIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
                            </span>
                          </span>
                        </Tooltip>
                      ) : (
                        <>
                          <FormattedNumber value={plan.rate ? plan.rate : 0} style={`currency`} currency="USD" />
                        </>
                      )}
                    </Typography>
                    {save > 0 && (
                      <Tooltip
                        title={
                          <Box sx={{ backgroundColor: "#F2EDE7", border: "none" }}>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                              SAVE ${save.toFixed(2)} VS COBRA
                            </Typography>
                          </Box>
                        }
                        arrow
                        placement="bottom"
                        PopperProps={{
                          sx: {
                            '& .MuiTooltip-tooltip': {
                              backgroundColor: '#F2EDE7',
                              border: 'none',
                              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            '& .MuiTooltip-arrow': {
                              color: '#F2EDE7',
                            },
                          },
                        }}
                      >
                        <Chip
                          label={`SAVE $${save.toFixed(2)} VS COBRA`}
                          sx={{
                            fontSize: '0.8rem',
                            backgroundColor: "#AFC3FF",
                            cursor: "pointer"
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6} sm={12}>
                  <Box sx={{ p: 2, backgroundColor: "#FFFFFF", display: "flex", flexDirection: "column", alignItems: "center", textAlign: 'center', height: 120, borderBottom: "2px solid #F2EDE7", borderLeft: { xs: "1px solid #F2EDE7", sm: "none" } }}>
                    <Typography variant="h6" sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}>Insurer</Typography>
                    <img
                      src={plan.carrier.largeLogo}
                      alt={plan.carrier.name}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} sm={12}>
                  <Box sx={{ p: 1, backgroundColor: "#FFFFFF", display: "flex", flexDirection: "column", alignItems: "center", textAlign: 'center', height: 80, borderBottom: "2px solid #F2EDE7", borderRight: { xs: "1px solid #F2EDE7", sm: "none" } }}>
                    <Typography variant="h6" sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}>Deductible</Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{deductible}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={12}>
                  <Box sx={{ p: 1, backgroundColor: "#FFFFFF", display: "flex", flexDirection: "column", alignItems: "center", textAlign: 'center', height: 80, borderBottom: "2px solid #F2EDE7", borderLeft: { xs: "1px solid #F2EDE7", sm: "none" } }}>
                    <Typography variant="h6" sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}>Max Out of Pocket</Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{outOfPocketMax}</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ p: 1, backgroundColor: "#FFFFFF", display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Button data-testid="choose-this-plan" variant="contained" color="primary" fullWidth onClick={() => { setOpenChoosePlan(true); setPlanToChoose(plan) }}>
                  Choose This Plan
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  data-testid="view-plan-details"
                  fullWidth
                  onClick={() => { setOpenViewPlan(true); setPlanToView(plan); }}
                  sx={{
                    textDecoration: 'underline',
                    '&:hover': {
                      textDecoration: 'underline',
                    }
                  }}
                >
                  View Plan Details
                </Button>

              </Box>
            </Grid>
          )
        })}
      </Grid>
      {planToView && openViewPlan && (
        <>
          <Dialog
            open={openViewPlan}
            onClose={() => { setOpenViewPlan(false); setPlanToView(null) }}
            fullWidth
            maxWidth="lg"
          >
            <ViewDetailsModalQuotit plan={planToView} close={() => { setOpenViewPlan(false); setPlanToView(null) }} />
          </Dialog>
        </>
      )}
      {planToChoose && openChoosePlan && (
        <>
          <Dialog
            open={openChoosePlan}
            onClose={() => { setOpenChoosePlan(false); setPlanToChoose(null) }}
            fullWidth
            maxWidth="lg"
          >
            <SelectPlanModalQuotit plan={planToChoose} close={() => { setOpenChoosePlan(false); setPlanToChoose(null) }} />
          </Dialog>
        </>
      )}
      <span ref={scrollSpan}></span>
    </>
  )
}

export default PlanOptionsJamie