import { useEffect, useState } from "react";
// import UpdateDetails from "./UpdateDetails"; // Existing Update Details component
import Household from "./Household"; // Existing Household component
import InsuranceDetails from "./InsuranceDetails"; // Existing Insurance Details component
import { useLazyQuery } from "@apollo/client";
import { useAuth } from "contexts/User";
import { Queries } from "services/apollo";

function UpdateInfo(props: { userId: string | undefined, onClose: () => void; }) {
    const [step, setStep] = useState(1);
    const { answers } = useAuth();

    const [loadRates, rateQuery] = useLazyQuery<{ total: number }, { effectiveDate: string }>(Queries.getRates, {
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (rateQuery.error) {
            console.error(rateQuery.error);
        }
    }, [rateQuery]);

    const handleSave = () => {
        const effectiveDate = answers?.get("insuranceDetails.effectiveDate");
        loadRates({ variables: { effectiveDate }, fetchPolicy: 'network-only' });
        setStep(2);
    };

    return (
        <>
            {step === 1 && (
                <InsuranceDetails
                    saved={handleSave}
                    userId={props.userId}
                    updateInfo={true}
                    cancel={props.onClose}
                />
            )}
            {step === 2 && (
                <Household
                    saved={() => { handleSave(); props.onClose() }}
                    cancel={props.onClose}
                    userId={props.userId}
                />
            )}
        </>
    );
}

export default UpdateInfo;
