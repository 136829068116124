import { DocumentData, SnapshotOptions, FirestoreDataConverter, QueryDocumentSnapshot, WithFieldValue } from "firebase/firestore";
import { QuotitHealthPlanDetails } from "services/Interfaces";
import { Plan } from "services/Interfaces";

export const QuotitPlanConverter: FirestoreDataConverter<QuotitHealthPlanDetails> = {
  toFirestore(plan: WithFieldValue<QuotitHealthPlanDetails>): DocumentData {
    return { ...plan };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): QuotitHealthPlanDetails {
    const data = snapshot.data(options);
    if (!data.recommendation) {
      data.recommendation = {};
    }
    return {
      id: data.id,
      benefitLevel: data.benefitLevel,
      benefitsLink: data.benefitsLink,
      providerLink: data.providerLink,
      summaryOfBenefitsLink: data.summaryOfBenefitsLink,
      exclusionsLink: data.exclusionsLink,
      disclaimer: data.disclaimer,
      rate: data.rate,
      name: data.name,
      carrierId: data.carrierId,
      effectiveDate: data.effectiveDate,
      rating: data.rating,
      contractStatus: data.contractStatus,
      visibility: data.visibility,
      source: data.source,
      ratingMethod: data.ratingMethod,
      baseRateUnit: data.baseRateUnit,
      carrier: { name: data.carrier.name, logo: data.carrier.logo, largeLogo: data.carrier.largeLogo },
      insuranceType: data.insuranceType,
      planType: data.planType,
      benefits: data.benefits,
      recommendation: {
        internalScore: data.recommendation.internalScore,
        aiScore: data.recommendation.aiScore,
        headline: data.recommendation.headline,
        explanation: data.recommendation.explanation,
        pros: data.recommendation.pros,
        cons: data.recommendation.cons
      }
    };
  },
};
export const PlanConverter: FirestoreDataConverter<Plan> = {
  toFirestore(plan: WithFieldValue<Plan>): DocumentData {
    return {
      nickname: plan.nickname,
      availableBenefit: plan.availableBenefit,
      maxBenefit: plan.maxBenefit,
      type: plan.type,
      employer: plan.employer,
      paymentType: plan.paymentType,
      paymentDueDate: plan.paymentDueDate,
      premium: plan.premium
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Plan {
    const data = snapshot.data(options);
    return {
      nickname: data.nickname,
      availableBenefit: data.availableBenefit,
      maxBenefit: data.maxBenefit,
      type: data.type,
      employer: data.employer,
      paymentType: data.paymentType,
      paymentDueDate: data.paymentDueDate,
      premium: data.premium,
      id: snapshot.id,
      ref: snapshot.ref
    };
  },
};