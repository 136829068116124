import { Cancel, Check, Edit, KeyboardArrowDown, UploadFile } from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, Menu, Typography, Fade, Link, Snackbar, TextField, IconButton, Card, Divider, Select, MenuItem, Dialog, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Radio, FormControlLabel, RadioGroup } from "@mui/material";
import { ChangeEvent, useEffect, useState, useCallback, useRef } from "react";
import { ReactComponent as InfoIcon } from "images/InfoIcon.svg";
import { ReactComponent as JamieImage } from "images/Jamie.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Mutations, Queries } from "services/apollo";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { collection, doc, orderBy, query, setDoc, deleteField, updateDoc, addDoc } from "firebase/firestore";
import { useAuth } from "contexts/User";
import { useFirebase } from "contexts/Firebase";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useUserData } from "contexts/User/data";
import Household, { CoveredPerson } from "components/HealthPlan/Household";
import { QuotitHealthPlanDetails } from "services/Interfaces";
import { QuotitPlanConverter } from "services/Firestore/Plan";
import { ref as storageRef, uploadBytes } from 'firebase/storage';
import moment from "moment";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';


function JamiePrototype() {
    const { user } = useAuth();
    const { firestore } = useFirebase();
    const [helpfulInfoAnchorEl, setHelpfulInfoAnchorEl] = useState<null | HTMLElement>(null);
    const helpfulInfoOpen = Boolean(helpfulInfoAnchorEl);
    const handleClickHelpfulInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
        setHelpfulInfoAnchorEl(event.currentTarget);
    };
    const handleCloseHelpfulInfo = () => {
        setHelpfulInfoAnchorEl(null);
    };
    const [messageThread, setMessageThread] = useState<Array<{
        id: string;
        text: string;
        user: boolean;
        timestamp: string;
        type: string;
        dynamicTemplate: string;
        data: any;
    }>>([]);
    // const ref = useChatScroll(messageThread);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [userMessage, setUserMessage] = useState("");
    const [chatType, setChatType] = useState("cold");
    const [hasCobra, setHasCobra] = useState(false);
    const [isTyping, setIsTyping] = useState(false);

    const [messageStream, setMessageStream] = useState<string[]>([]);
    const [cancelMessage] = useMutation(Mutations.cancelMessage);
    const [clearMessages] = useMutation(Mutations.clearMessages);
    
    const cancel = () => {
        cancelMessage();
        setUserMessage("");
    }
    const clear = () => {
        clearMessages();
        setUserMessage("");
        setMessageThread([]);
    }
    useEffect(() => {
        if (messageStream.join("") === messageThread[messageThread.length - 1]?.text) {
            console.log("Message Stream is the same as the last message in the thread")
            setMessageStream([]);
        }
    }, [messageStream, messageThread])
    const sendMessageStream = (message: string, isPrompt = false) => {
        let stream: string[] = [];
        const sse = new EventSource('http://localhost:5001/when-local/us-central1/sendMessage?uid=' + user?.uid + '&message=' + message + '&isPrompt=' + isPrompt,
            {
                withCredentials: true,
            });
        function getRealtimeData(e: any) {
            // console.log(e);
            // process the data here,
            // console.log("| " + e.data);
            const parsed = JSON.parse(e.data);
            if (parsed.done) {
                sse.close();
                // setMessageStream([]);
            } else {
                stream.push(parsed.content);
                setMessageStream([...stream]);
            }
        }
        sse.onmessage = e => getRealtimeData(e);
        sse.onerror = (e) => {
            console.error("Error with SSE", e)
            setMessageStream([]);
            sse.close();
        }
    };
    // useEffect(() => {
    //     if (chatType === "cold") {
    //         setDoc(doc(firestore, "users", user?.uid || "a", "settings", "chat"), {
    //             chatContext: "cold",
    //         }, { merge: true });
    //     } else {
    //         setDoc(doc(firestore, "users", user?.uid || "a", "settings", "chat"), {
    //             chatContext: "exitCase",
    //         }, { merge: true });
    //     }
    // }, [chatType, firestore, user?.uid]);
    // useEffect(() => {
    //     setDoc(doc(firestore, "users", user?.uid || "a", "settings", "chat"), {
    //         hasCobra,
    //     }, { merge: true });
    // }, [hasCobra, firestore, user?.uid]);
    const send = async () => {
        const message = userMessage;
        setUserMessage("");
        setLoading(true);
        // await sendMessage({ variables: { message } });
        sendMessageStream(message);
    };
    const sendPrompt = async (message: string) => {
        setLoading(true);
        sendMessageStream(message, true);
    }
    // const [userSettings, ,] = useDocument<any>(
    //     doc(firestore, "users", user?.uid || "a", "settings", "chat"),
    //     {
    //         snapshotListenOptions: { includeMetadataChanges: true },
    //     }
    // )
    const [messages, ,] = useCollection<any>(query(
        collection(firestore, "users", user?.uid || "a", "messages"),
        orderBy("timestamp", "asc")
    ),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    )
    useEffect(() => {
        if (messages) {
            const newMessages = messages.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            });
            console.log(newMessages);
            setMessageThread(newMessages.map((message) => {
                return {
                    id: message.id,
                    text: message.text,
                    user: message.type === "human",
                    type: message.type,
                    dynamicTemplate: message.dynamicTemplate,
                    data: message.data,
                    timestamp: message.timestamp
                }
            }));
            setLoading(false);
        }
    }, [messages]);
    const resetChat = async () => {
        const userId = user?.uid || "a";
        const convosRef = collection(firestore, `users/${userId}/convos`);
        const newConvoRef = doc(convosRef);

        const sentimentData = {
            sentiment: feedback.sentiment,
            additionalFeedback: additionalFeedback,
            canContact: canContact,
            messages: messageThread.map((message) => ({
                id: message.id,
                text: message.text,
                user: message.user,
                type: message.type,
                dynamicTemplate: message.dynamicTemplate,
                data: message.data,
                timestamp: message.timestamp,
                feedback: feedback[message.id] || null // Include feedback if available
            }))
        };

        try {
            await setDoc(newConvoRef, sentimentData);

            // Clear the current chat
            await clear();
            await setDoc(doc(firestore, "users", userId, "settings", "chat"), {
                chatContext: chatType,
                hasCobra: hasCobra,
                feedback: feedback,
                started: new Date(),
            }, { merge: false });
            sendMessageStream("start");

            window.location.reload()

        } catch (error) {
            console.error("Error resetting chat: ", error);
        }
    };




    const resetInsurance = async () => {
        await setDoc(doc(firestore, "users", user?.uid || "a", "answers", user?.uid || "a"),
            {
                insuranceDetails: deleteField(),
            }, { merge: true });
    }
    const [feedback, setFeedback] = useState<{ [key: string]: string }>({});
    const [feedbackVisible, setFeedbackVisible] = useState<{ [key: string]: boolean }>({});
    const feedbackTimers = useRef<{ [key: string]: NodeJS.Timeout }>({});

    const handleFeedbackVisibility = (messageId: string) => {
        if (feedbackTimers.current[messageId]) {
            clearTimeout(feedbackTimers.current[messageId]);
        }
        feedbackTimers.current[messageId] = setTimeout(() => {
            setFeedbackVisible((prev) => ({ ...prev, [messageId]: true }));
        }, 60000); // 1 minute
    };

    const resetFeedbackTimer = (messageId: string) => {
        setFeedbackVisible((prev) => ({ ...prev, [messageId]: false }));
        handleFeedbackVisibility(messageId);
    };

    useEffect(() => {
        messageThread.forEach((message) => {
            if (!message.user) {
                handleFeedbackVisibility(message.id);
            }
        });
    }, [messageThread]);

    const handleFeedback = useCallback(async (messageId: string, feedbackType: string) => {
        console.log("handleFeedback called with messageId:", messageId, "and feedbackType:", feedbackType);
        try {
            const messageRef = doc(firestore, "users", user?.uid || "a", "messages", messageId);
            await updateDoc(messageRef, {
                feedback: feedbackType
            });
            setFeedback((prev) => ({ ...prev, [messageId]: feedbackType }));
            console.log("Feedback updated successfully for message:", messageId);
        } catch (error) {
            console.error("Error updating feedback: ", error);
        }
    }, [firestore, user?.uid]);

    const handleSentiment = useCallback((sentimentType: string) => {
        console.log("handleSentiment called with sentimentType:", sentimentType);
        setFeedback((prev) => ({ ...prev, sentiment: sentimentType }));
        console.log("Sentiment updated successfully");
    }, []);

    const [selectedSentiment, setSelectedSentiment] = useState<string | null>(null);
    const [additionalFeedback, setAdditionalFeedback] = useState<string>("");
    const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);
    const [sentimentVisible, setSentimentVisible] = useState<boolean>(true);
    const [showThankYou, setShowThankYou] = useState<boolean>(false);
    const [canContact, setCanContact] = useState<boolean>(true);

    const handleContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCanContact(event.target.value === 'yes');
    };

    useEffect(() => {
        if (feedbackSubmitted && selectedSentiment) {
            handleSentiment(selectedSentiment);
            setShowThankYou(true);
            setTimeout(() => {
                setSelectedSentiment(null);
                setAdditionalFeedback("");
                setFeedbackSubmitted(false);
                setShowThankYou(false);
                setSentimentVisible(false);
            }, 500000); 
        }
    }, [feedbackSubmitted, selectedSentiment, handleSentiment]);

    const handleSentimentClick = (sentimentType: string) => {
        setSelectedSentiment(sentimentType);
        setSentimentVisible(true);
    };
    const handleSubmitFeedback = () => {
        console.log("Additional feedback submitted:", additionalFeedback);
        setFeedbackSubmitted(true);
        setSentimentVisible(false);

        // Ensure selectedSentiment is a string before updating the state
        const sentiment = selectedSentiment || "";

        // Update the state with the feedback
        setFeedback((prev) => ({
            ...prev,
            sentiment: sentiment,
            additionalFeedback: additionalFeedback
        }));

        console.log("Feedback and additional feedback updated in state");
    };
    
        console.log("Rendering component. sentimentVisible:", sentimentVisible, "feedbackSubmitted:", feedbackSubmitted);

    const createWhenBenefit = async () => {
        const whenBenefitData = {
            company: "When",
            companyId: "when",
            createdAt: new Date(),
            exitCode: "4BMjlWLAr4vCpsAz6FbN",
            maxBenefit: 5000,
            name: "Quandale Dingle Jr",
            remainingBenefit: 3224,
            status: "active",
            terminationDate: "06/30/2024",
            time: {
                betweenRequests: {
                    measure: "months",
                    value: 6,
                },
                firstRequest: {
                    measure: "months",
                    value: 6,
                },
            },
            type: "Managed Health",
            updatedAt: new Date(),
        };

        const userId = user?.uid || "defaultUserId"; // Replace with actual user ID if needed
        const whenBenefitsRef = collection(firestore, `users/${userId}/whenBenefits`);

        try {
            if (!user) {
                console.error("User is not authenticated");
                return;
            }

            console.log(`Attempting to create When Benefit in collection: users/${userId}/whenBenefits`);
            const docRef = await addDoc(whenBenefitsRef, whenBenefitData);
            console.log("When Benefit created successfully with ID:", docRef.id, "and data:", whenBenefitData);
        } catch (error) {
            console.error("Error creating When Benefit:", error);
        }
    };


    const createDemoService = async () => {
        const services = [
            {
                serviceName: "401k Rollover",
                serviceProvider: "Morgan Stanley",
                tileBodyText: "Test tile body text",
                tileHeader: "Test Tile Header",
                tileBackgroundColor: "#b13535",
                phoneNumber: "888-454-3965",
                redeemCode: "323419",
                serviceCategory: ["Financial Services"],
            },
            {
                serviceName: "ETRADE",
                serviceProvider: "E*TRADE",
                tileBodyText: "ETRADE tile body text",
                tileHeader: "ETRADE Tile Header",
                tileBackgroundColor: "#123456",
                phoneNumber: "800-387-2331",
                redeemCode: "323419",
                serviceCategory: ["Financial Services"],
            },
            {
                serviceName: "Career Sandwich",
                serviceProvider: "Career Services",
                tileBodyText: "Career Sandwich tile body text",
                tileHeader: "Career Sandwich Tile Header",
                tileBackgroundColor: "#654321",
                phoneNumber: "800-321-3453",
                redeemCode: "323419",
                serviceCategory: ["Career Services"],
            }
        ];

        const companyId = "when"; // Replace with the actual company ID in prod
        const serviceMarketplaceRef = collection(firestore, `companies/${companyId}/serviceMarketplace`);
        const userId = user?.uid; 
        const userRef = doc(firestore, `users/${userId}`);

        try {
            if (!user) {
                console.error("User is not authenticated");
                return;
            }

            for (const service of services) {
                const demoServiceData = {
                    createdAt: new Date(),
                    CTAButton: false,
                    CTAButtonText: "",
                    CTAButtonURL: "",
                    fontColor: "#ffffff",
                    footer: "footer text",
                    isActive: true,
                    phoneButton: true,
                    tilesTulipsPrint: true,
                    tilesVisibility: "when",
                    ...service
                };

                console.log(`Attempting to create demo service: ${service.serviceName} in collection: companies/${companyId}/serviceMarketplace`);
                const docRef = await addDoc(serviceMarketplaceRef, demoServiceData);
                console.log(`${service.serviceName} created successfully with ID:`, docRef.id, "and data:", demoServiceData);

                // Create a record for the user
                await setDoc(userRef, {
                    ...demoServiceData,
                    userId: user.uid,
                    companyId: companyId,
                }, { merge: true });
                console.log(`User record created successfully for service: ${service.serviceName} at: users/${userId}`);
            }
        } catch (error) {
            console.error("Error creating demo service or user record:", error);
        }
    };

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            setIsTyping(false);
        }, 60000); // 60 seconds

        return () => clearTimeout(typingTimeout);
    }, [userMessage]);


        return (
            <Box>
                <Container>
                    <Card sx={{
                        p: 4,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}>
                        <Typography variant="h1">Jamie Prototype</Typography>
                        <Typography variant="h3">Customize Inputs</Typography>
                        <Divider></Divider>
                        <Select value={chatType} onChange={(e) => { setChatType(e.target.value) }}>
                            <MenuItem value="cold">Cold start</MenuItem>
                            <MenuItem value={"exitCase"}>From an exit Case</MenuItem>
                        </Select>
                        <Select value={hasCobra} onChange={(e) => { setHasCobra(e.target.value === "true") }}>
                            <MenuItem selected={!hasCobra} value={"false"}>No Cobra Uploaded</MenuItem>
                            <MenuItem selected={hasCobra} value={"true"}>Cobra Uploaded</MenuItem>
                        </Select>
                        <Box>
                            <Button onClick={resetChat}>Reset Chat</Button>
                            <Button onClick={resetInsurance}>Reset Insurance</Button>
                            <Button onClick={createDemoService}>Create Demo Service</Button>
                            <Button onClick={createWhenBenefit}>Create When Benefit</Button> 
                        </Box>
                    </Card>
                </Container>
                <Box sx={{
                    backgroundColor: "background.default",
                    py: 4
                }}
                    id="jamie"
                >
                    <Container>
                        <Box sx={{
                            backgroundColor: "white.main",
                            borderRadius: "12px"
                        }}>
                            <Box sx={{
                                backgroundColor: "beige.main",
                                borderRadius: "12px 12px 0px 0px",
                                display: "flex",
                                flexDirection: { xs: "column", sm: "row" },
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "19px 16px",
                                gap: "10px"
                            }}>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "21px",
                                    ml: { xs: "0px", sm: "54px" }
                                }}>
                                    <JamieImage width="40px" height="40px"></JamieImage>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        gap: "3px"
                                    }}>
                                        <Typography color={"primary"}>Jamie, Health Insurance Advocate</Typography>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px"
                                        }}>
                                            <Box sx={{
                                                backgroundColor: "#26BE00",
                                                borderRadius: "100%",
                                                width: "8px",
                                                height: "8px"
                                            }}></Box>
                                            <Typography fontSize="11px">Online</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Button onClick={handleClickHelpfulInfo} disableRipple sx={{
                                    backgroundColor: "primary.main",
                                    borderRadius: "12px",
                                    padding: "6px 20px",
                                    display: "flex",
                                    maxWidth: "277px",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    "&:hover": {
                                        backgroundColor: "primary.main",
    
                                    }
                                }}>
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer"
                                    }}
                                    >
                                        <InfoIcon width="16px"></InfoIcon>
                                        <Typography color={"white.main"} sx={{ pl: "10px" }}>Helpful <Box component={"span"} fontWeight={600}>Information</Box></Typography>
                                    </Box>
                                    <KeyboardArrowDown color="secondary"></KeyboardArrowDown>
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={helpfulInfoAnchorEl}
                                    open={helpfulInfoOpen}
                                    onClose={handleCloseHelpfulInfo}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                        sx: { py: 0 }
                                    }}
                                    TransitionComponent={Fade}
                                    PaperProps={{
                                        sx: {
                                            borderRadius: "0px 0px 12px 12px",
                                            mt: "-10px"
                                        }
                                    }}
                                >
                                    <Box sx={{
                                        backgroundColor: "primary.main",
                                        px: "20px",
                                        py: "24px",
                                        maxWidth: "277px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2
                                    }}>
                                        <Typography color="white.main">If you are worried about sharing your informaiton with Jamie please <Link href="/privacy" fontWeight={600} color={"white.main"}>review our privacy policy</Link></Typography>
    
                                    </Box>
                                </Menu>
                            </Box>
                            <Box>
                                <Box sx={{
    
                                    height: "65vh",
                                    maxHeight: "65vh",
                                    overflowY: "scroll",
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                    gap: "14px",
                                    px: { xs: "12px", sm: "140px" },
                                    py: "20px"
                                }}>
                                    {loading && <Box sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60px",
                                        padding: "10px 15px",
                                        backgroundColor: "#F1F1F1",
                                        borderRadius: "17px 17px 17px 2px",
                                        width: "fit-content",
                                        maxWidth: { xs: "100%", sm: "50%" }
                                    }}>
                                        <CircularProgress color="primary" />
                                    </Box>
                                    }
                                    {messageStream && messageStream.length > 0 &&
                                        <Box sx={
                                            {
                                                display: "block",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                gap: "10px",
                                                marginRight: "auto",
                                                backgroundColor: "#F1F1F1",
                                                color: "black.main",
                                                p: 4,
                                                maxWidth: { xs: "100%", sm: "75%" },
                                                borderRadius: "17px 17px 17px 2px",
                                            }}
                                        >
                                            <Markdown remarkPlugins={[remarkGfm]}>
                                                {messageStream.join("")}
                                            </Markdown>
                                        </Box>
                                    }
{messageThread.map((message, index) => {
    const isLastMessage = index === messageThread.length - 1;
    if (message.type === "dynamic") {
        return (
            <DynamicTemplate template={message.dynamicTemplate} data={message.data} key={message.id} sendPrompt={sendPrompt}></DynamicTemplate>
        )
    }
    return (
        <Box key={message.id}>
            <Box sx={
                message.user ? {
                    display: "block",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "10px",
                    marginLeft: "auto",
                    backgroundColor: "primary.main",
                    color: "white.main",
                    p: 4,
                    maxWidth: { xs: "100%", sm: "75%" },
                    borderRadius: "17px 17px 2px 17px",
                    position: "relative", // Added for positioning feedback icons
                    padding: "3px 3px 3px 20px",
                } : {
                    display: "block",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                    marginRight: "auto",
                    backgroundColor: "#F1F1F1",
                    color: "black.main",
                    p: 4,
                    maxWidth: { xs: "100%", sm: "75%" },
                    borderRadius: "17px 17px 17px 2px",
                    position: "relative", // Added for positioning feedback icons
                    padding: "3px 20px 30px 20px",
                }
            }>
                <Markdown remarkPlugins={[remarkGfm]}>
                    {message.text}
                </Markdown>
                {!message.user && (
                    <Box sx={{
                        position: "absolute", // Positioning feedback icons
                        bottom: "10px",
                        right: "10px",
                        display: "flex",
                        gap: "5px",
                        paddingTop: { xs: "30px", sm: "30px" } // Add padding above thumbs on mobile
                    }}>
                        <IconButton color="success" onClick={() => handleFeedback(message.id, "positive")}>
                            <ThumbUpAltIcon fontSize="small" sx={{ color: "green", opacity: feedback[message.id] === "positive" ? 0.4 : 0.2 }} />
                        </IconButton>
                        <IconButton color="success" onClick={() => handleFeedback(message.id, "negative")}>
                            <ThumbDownAltIcon fontSize="small" sx={{ color: "green", opacity: feedback[message.id] === "negative" ? 0.4 : 0.2 }} />
                        </IconButton>
                    </Box>
                )}
            </Box>
            {!message.user && feedbackVisible[message.id] && !isTyping && isLastMessage && (
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    marginRight: "auto",
                    backgroundColor: "#F1F1F1",
                    color: "black.main",
                    p: 4,
                    maxWidth: { xs: "100%", sm: "75%" },
                    borderRadius: "17px 17px 17px 2px",
                    mt: 2 // Add margin-top to separate the feedback box
                }}>
                    {showThankYou ? (
                        <Typography sx={{ mt: 2, alignContent: "center", paddingBottom:"1em" }}>Thank you for your feedback!</Typography>
                    ) : sentimentVisible ? (
                        <>
                            <Typography fontSize="sm">How was your experience?</Typography>
                            <Box sx={{ display: "flex", gap: "5px" }}>
                                <IconButton onClick={() => handleSentimentClick("veryDissatisfied")}>
                                    <SentimentVeryDissatisfiedIcon fontSize="small" sx={{ color: "gray", opacity: selectedSentiment === "veryDissatisfied" ? 0.4 : 0.2 }} />
                                </IconButton>
                                <IconButton onClick={() => handleSentimentClick("dissatisfied")}>
                                    <SentimentDissatisfiedIcon fontSize="small" sx={{ color: "gray", opacity: selectedSentiment === "dissatisfied" ? 0.4 : 0.2 }} />
                                </IconButton>
                                <IconButton onClick={() => handleSentimentClick("neutral")}>
                                    <SentimentSatisfiedAltIcon fontSize="small" sx={{ color: "gray", opacity: selectedSentiment === "neutral" ? 0.4 : 0.2 }} />
                                </IconButton>
                                <IconButton onClick={() => handleSentimentClick("satisfied")}>
                                    <SentimentSatisfiedIcon fontSize="small" sx={{ color: "gray", opacity: selectedSentiment === "satisfied" ? 0.4 : 0.2 }} />
                                </IconButton>
                                <IconButton onClick={() => handleSentimentClick("verySatisfied")}>
                                    <SentimentVerySatisfiedIcon fontSize="small" sx={{ color: "gray", opacity: selectedSentiment === "verySatisfied" ? 0.4 : 0.2 }} />
                                </IconButton>
                            </Box>
                            {selectedSentiment && (
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "5px", mt: 2 }}>
                                    <TextField
                                        label="Additional Feedback"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        value={additionalFeedback}
                                        onChange={(e) => setAdditionalFeedback(e.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmitFeedback}
                                    >
                                        Submit Feedback
                                    </Button>
                                    {(selectedSentiment === "dissatisfied" || selectedSentiment === "veryDissatisfied") && (
                                        <>
                                            <Typography sx={{ fontSize: "small", mt: 2, alignContent: "center", paddingBottom:"1em" }}>Can a When agent contact you about your experience?</Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center' }}>
                                                <RadioGroup row value={canContact ? 'yes' : 'no'} onChange={handleContactChange}>
                                                    <FormControlLabel 
                                                        value="yes" 
                                                        control={<Radio />} 
                                                        label={<Typography sx={{ fontSize: '0.875rem' }}>Yes</Typography>} 
                                                    />
                                                    <FormControlLabel 
                                                        value="no" 
                                                        control={<Radio />} 
                                                        label={<Typography sx={{ fontSize: '0.875rem' }}>No</Typography>} 
                                                    />
                                                </RadioGroup>
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            )}
                            <Button size="small" onClick={() => resetFeedbackTimer(message.id)}>I am still here</Button>
                        </>
                    ) : null}
                </Box>
            )}
        </Box>
    );
}).reverse()}
                                    <Typography fontSize={"11px"}>Jamie</Typography>
                                </Box>
                            </Box>
                            <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}>
                        <TextField 
                            value={userMessage} 
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    send();
                                }
                            }} 
                            onChange={(e) => { 
                                setUserMessage(e.target.value);
                                setIsTyping(true); 
                            }} 
                            sx={{
                                backgroundColor: "white.main",
                                borderRadius: "0px 0px 12px 12px",
                                padding: "10px 15px",
                                width: "100%",
                                maxWidth: { xs: "100%", sm: "50%" }
                            }}
                        />
                        <Button onClick={send} color="primary" variant="contained" sx={{
                            padding: "5px 5px 5px 5px",
                            margin: "10px",
                        }}>
                            Send
                        </Button>
                        <Button onClick={cancel} color="secondary" variant="contained" sx={{
                            padding: "5px 5px 5px 5px",
                            margin: "10px",
                        }}>
                            Cancel
                        </Button>
                        <Button onClick={clear} color="warning" variant="contained" sx={{
                            padding: "5px 5px 5px 5px",
                            margin: "10px",
                        }}>
                            Clear
                        </Button>
                        <Button onClick={resetChat} color="primary" variant="contained" sx={{
                            padding: "5px 10px 5px 10px",
                            margin: "10px",
                        }}
                        >
                            Save/Reset</Button>
                    </Box>
                        </Box>
                    </Container>
                </Box>
                <Snackbar open={error !== ""} autoHideDuration={6000} onClose={() => { setError("") }} />
            </Box>
        );
}
function Recommendations({ sendPrompt }: { sendPrompt: (message: string) => void }) {
    const { firestore } = useFirebase();
    const { user, answers } = useAuth();
    const [plans, plansLoading, plansError] = useCollection<QuotitHealthPlanDetails>(
        query<QuotitHealthPlanDetails>(
            collection(firestore, `users/${user?.uid}/effectiveDate/${answers?.get("insuranceDetails.effectiveDate")}/plans`).withConverter(QuotitPlanConverter),
            orderBy("recommendation.internalScore", "desc"),
        )
    );

    const setSelectedPlan = async (planId: string, planDetails: any) => {
        await setDoc(doc(firestore, "users", user?.uid || "a", "settings", "chat"), {
            selectedPlan: planId,
            selectedPlanDetails: planDetails, // Store plan details
        }, { merge: true });
        sendPrompt("I have selected a plan, provide a quick overview of the details");
    }

    if (plansLoading) {
        return (
            <Box>
                <CircularProgress />
            </Box>
        )
    }
    if (plansError) {
        return (
            <Box>
                <Typography>Error loading plans</Typography>
            </Box>
        )
    }
    return (
        <Box>
            <Typography>Recommended Plans</Typography>
            <Box>
                {plans?.docs.filter((plan) => plan.get('insuranceType') === "HealthOnExchange").slice(0, 3).map((doc) => {
                    const plan = doc.data();
                    const deductible = plan.benefits.filter(benefit => benefit.enum === "ACA1MedicalDeductible" || benefit.enum === "ACA1MedicalDrugDeductible" || benefit.enum === "AnnualDeductible")[0];
                    const oopMax = plan.benefits.filter(benefit => benefit.enum === "ACA1EHBDrugOOPMax" || benefit.enum === "MaximumAnnualCopay")[0];
                    const planDetails = {
                        name: plan.name,
                        rate: plan.rate,
                        deductible: deductible.inNetwork,
                        oopMax: oopMax.inNetwork
                    };
                    return (
                        <Box key={doc.id} sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                            backgroundColor: "beige.main",
                            p: 4,
                            borderRadius: "17px 17px 2px 17px",
                            maxWidth: { xs: "100%", sm: "75%" },
                            margin: "1em"
                        }}>
                            <Typography>{plan.name}</Typography>
                            <Typography>Premium: {plan.rate}</Typography>
                            <Typography>Deductible: {deductible.inNetwork}</Typography>
                            <Typography>Out of Pocket Max: {oopMax.inNetwork}</Typography>
                            <Button variant="contained" color="primary" onClick={() => { setSelectedPlan(plan.id, planDetails) }}>Select</Button>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}
function EnrollNow() {
    const { firestore } = useFirebase();
    const { user, answers } = useAuth();
    const [userSettings, ,] = useDocument<any>(
        doc(firestore, "users", user?.uid || "a", "settings", "chat"),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    )
    const [plan, planLoading, planError] = useDocument<QuotitHealthPlanDetails>(
        doc(firestore, `users/${user?.uid}/effectiveDate/${answers?.get("insuranceDetails.effectiveDate")}/plans/${userSettings?.get("selectedPlan") || "a"}`).withConverter(QuotitPlanConverter),
        {
            snapshotListenOptions: {
                // includeMetadataChanges: true
            },
        });
    const [fetchEnrollmentUrl, enrollmentUrl] = useLazyQuery<{ generateEnrollmentUrl: { redirectUrl: string } }, { effectiveDate: string, planId: string, carrierId: string }>(Queries.generateEnrollmentUrl, {

        notifyOnNetworkStatusChange: true,
    });
    useEffect(() => {
        if (plan && answers) {
            fetchEnrollmentUrl({
                variables: {
                    effectiveDate: answers.get("insuranceDetails.effectiveDate"),
                    planId: plan.id.toString(),
                    carrierId: plan.get("carrierId")?.toString()
                },
            })
        }
    }, [answers, fetchEnrollmentUrl, plan])
    // function redirect(url: string) {
    //     window.location.href = url;
    // }
    async function startEnrollment() {
        if (!userSettings?.get("enrollmentStatus")) {
            await setDoc(doc(firestore, "users", user?.uid || "a", "settings", "chat"), {
                enrollmentStatus: "started",
            }, { merge: true });
        }
    }
    if (planLoading) {
        return (
            <Box>
                Loading <CircularProgress />
            </Box>
        )
    }
    if (planError) {
        return (
            <Box>
                <Typography>Error loading plan</Typography>
            </Box>
        )
    }
    return (
        <Box>
            <Typography>Enrolling in: {plan?.data()?.name}</Typography>
            <Button variant="contained" color="primary" disabled={!enrollmentUrl?.data?.generateEnrollmentUrl?.redirectUrl || !enrollmentUrl?.data?.generateEnrollmentUrl?.redirectUrl.includes("http")} onClick={() => { startEnrollment() }}></Button>
        </Box>
    )
}
function UploadResume() {
    const { firestore, storage } = useFirebase();
    const { user } = useAuth();
    const [uploading, setUploading] = useState(false);
    const uploadResume = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        setUploading(true);
        try {
            const file = e.target.files[0];
            const { name } = file;
            const uploadRef = storageRef(storage, `users/${user?.uid}/resumes/${name}`)
            await uploadBytes(uploadRef, file);
            setDoc(doc(firestore, "users", user?.uid || "a", "settings", "chat"), {
                resume: {
                    name,
                    fileName: name,
                    path: `users/${user?.uid}/resumes/${name}`,
                    type: file.type
                },
            }, { merge: true });
        } catch (e) {
            console.error(e);
        } finally {
            setUploading(false);
        }
    }

    return (
        <Box>
            <Button disabled={uploading} variant="contained" color="primary" component="label" startIcon={<UploadFile />}>Upload Resumé<input onChange={uploadResume} type="file" hidden /></Button>
        </Box>
    )
}
function DynamicTemplate({ template, data, sendPrompt }: { template: string, data: any, sendPrompt: (message: string) => void }) {
    const { answers } = useUserData();
    const [members, setMembers] = useState<{ [key: string]: CoveredPerson }>({});
    const [editHouseholdOpen, setEditHouseholdOpen] = useState(false);
    const [editZipcode, setEditZipcode] = useState(false);
    const [editCounty, setEditCounty] = useState(false);
    const [zipcode, setZipcode] = useState("");
    const [county, setCounty] = useState("");
    const [editEffectiveDate, setEditEffectiveDate] = useState(false);
    const [effectiveDate, setEffectiveDate] = useState("");
    const [GET_GEOGRAPHY] = useLazyQuery(Queries.GET_GEOGRAPHY);
    const [counties, setCounties] = useState<{ text: string, value: { fips: string, name: string, state: string, zipcode: string } }[]>([]);
    const [loadRates, rateQuery] = useLazyQuery<{ total: number }, { effectiveDate: string }>(Queries.getRates, {
        notifyOnNetworkStatusChange: true,
    });
    const getCounties = async (zipcode: string) => {
        const counties = await GET_GEOGRAPHY({
            query: Queries.GET_GEOGRAPHY,
            variables: {
                zipcode: zipcode
            }
        })
        console.log(`counties `, counties.data)
        return setCounties(counties.data.geographyByZip.map((county: { fips: string, name: string, state: string, zipcode: string }) => {
            return { text: county.name, value: county }
        }));
    }
    const [promptSent, setPromptSent] = useState(true);
    useEffect(() => {
        const mems = answers?.get('insuranceDetails.household.members');
        if (mems === undefined) {
            // create members
            setMembers({});
        } else {
            setMembers(mems);
        }
        if (answers?.get("insuranceDetails.zipcode")) {
            setZipcode(answers?.get("insuranceDetails.zipcode"));
        }
        if (answers?.get("insuranceDetails.effectiveDate")) {
            setEffectiveDate(answers?.get("insuranceDetails.effectiveDate"));
        }
    }, [answers]);
    const updateAnswers = async (key: string, value: any) => {
        if (answers) {
            await updateDoc(answers.ref, {
                [key]: value
            });
        }
    }
    if (template === "showRecommendedPlans") {
        return (
            <Box>
                <Recommendations sendPrompt={sendPrompt} />
            </Box>
        )
    }
    if (template === "resumeViewer") {
        return (
            <Box>
                <Card sx={{ p: 2, backgroundColor: "#DDD" }}>
                    <Markdown remarkPlugins={[remarkGfm]}>
                        {data.content}
                    </Markdown>
                </Card>
            </Box>
        )
    }
    if (template === "uploadResume") {
        return (
            <Box>
                <UploadResume />
            </Box>
        )
    }
    if (template === "enrollInSelectedPlan") {
        return (
            <Box sx={{
                display: "block",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                marginLeft: "auto",
                backgroundColor: "beige.main",
                p: 4,
                maxWidth: { xs: "100%", sm: "75%" },
                borderRadius: "17px 17px 2px 17px",
            }}>
                <EnrollNow />
            </Box>
        );
    }
    if (template === "showEnrollmentInformation" || template === "updateEnrollmentInformation") {
        return (
            <Box sx={{
                display: "block",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                marginLeft: "auto",
                backgroundColor: "beige.main",
                p: 4,
                maxWidth: { xs: "100%", sm: "75%" },
                borderRadius: "17px 17px 2px 17px",
            }}>
                <Typography>Your Enrollment Details</Typography>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px"
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center"
                    }}>
                        {!editEffectiveDate && <>
                            <Typography>Effective Date: {moment(answers?.get("insuranceDetails.effectiveDate")).format("MMMM D")}</Typography>
                            <IconButton onClick={() => { setEditEffectiveDate(true) }}><Edit fontSize="small" /></IconButton>
                        </>}
                        {editEffectiveDate && <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                        }}>
                            <Typography>Effective Date: </Typography>
                            <Select value={effectiveDate} onChange={(e) => { setEffectiveDate(e.target.value) }}>
                                {Array.from(Array(4).keys()).map((i) => {
                                    const date = moment().add(i + 1, "months").startOf("month");
                                    return (
                                        <MenuItem key={date.format("YYYY-MM-DD")}
                                            value={date.format("YYYY-MM-DD")}>
                                            {date.format("MMMM D")}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <IconButton onClick={() => { setPromptSent(false); updateAnswers("insuranceDetails.effectiveDate", effectiveDate); setEditEffectiveDate(false) }}><Check fontSize="small" /></IconButton>
                            <IconButton onClick={() => { setEditEffectiveDate(false) }}><Cancel fontSize="small" /></IconButton>
                        </Box>}
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center"
                    }}>
                        {!editZipcode && <>
                            <Typography>Zipcode: {answers?.get("insuranceDetails.zipcode")}</Typography>
                            <IconButton onClick={() => { setEditZipcode(true) }}><Edit fontSize="small" /></IconButton>
                        </>}
                        {editZipcode && <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                        }}>
                            {!editCounty && <>
                                <Typography>Zipcode: </Typography>
                                <TextField value={zipcode} onChange={(e) => { setZipcode(e.target.value) }}></TextField>
                                <IconButton onClick={() => { getCounties(zipcode); setEditCounty(true) }}><Check fontSize="small" /></IconButton>
                            </>}
                            {editCounty && <>
                                <Typography>County: </Typography>
                                <Select value={county} onChange={(e) => { setCounty(e.target.value) }}>
                                    {counties.map((county) => {
                                        return (
                                            <MenuItem key={county.text}
                                                value={county.text}>
                                                {county.text}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                <IconButton onClick={() => {
                                    updateAnswers("insuranceDetails.zipcode", zipcode);
                                    updateAnswers("insuranceDetails.county", counties.find(p => p.text === county)?.value);
                                    setPromptSent(false);
                                    setEditZipcode(false);
                                    setEditCounty(false);
                                }}><Check fontSize="small" /></IconButton>
                            </>}
                            <IconButton onClick={() => { setEditZipcode(false); setEditCounty(false); }}><Cancel fontSize="small" /></IconButton>
                        </Box>}
                    </Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 400 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="center">Date of Birth</TableCell>
                                    <TableCell align="center">Gender</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries(members).map(([key, member], index) => (
                                    <TableRow
                                        key={key}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {member.name}
                                        </TableCell>
                                        <TableCell align="center">{member.dob}</TableCell>
                                        <TableCell align="center">{member.gender}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button variant="outlined" onClick={() => { setEditHouseholdOpen(true) }}>Update Household</Button>
                    <Button variant="contained" disabled={promptSent} color="primary" onClick={async () => {
                        setPromptSent(true);
                        await loadRates({
                            variables: {
                                effectiveDate: effectiveDate
                            },
                            fetchPolicy: "network-only"
                        });
                        sendPrompt("I have completed the form, please provide the recommended plans");
                    }}>Continue</Button>
                    {rateQuery.loading && <Typography>Refreshing Plans:<CircularProgress /> </Typography>}
                    <Dialog
                        open={editHouseholdOpen}
                        onAbort={() => { setEditHouseholdOpen(false); }}
                        fullWidth
                        maxWidth="lg"
                    >
                        <Box sx={{
                            p: 4,
                            width: "100%"
                        }}>
                            <Household saved={() => { setPromptSent(false); setEditHouseholdOpen(false) }} cancel={() => { setEditHouseholdOpen(false) }} />
                        </Box>
                    </Dialog>
                </Box>

            </Box>
        );
    }
    return (
        <Box>
            <Typography>Missing template: {template}</Typography>
        </Box>
    );
}
export default JamiePrototype;